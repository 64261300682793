import React, { useState } from 'react';

import { Menu as MenuIcon, Search as SearchIcon } from '@mui/icons-material';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { Box, IconButton, Stack } from '@mui/material';
import colors from '@packs/assets/theme/base/colors';
import { SearchBusinessCategory } from '@packs/components/UI';
import { MobileMenu } from '@packs/components/UI/AppHeader/components/MobileMenu';
import LogoIcon from '@packs/images/icons/LogoBillWinner';
import LogoBlackIcon from '@packs/images/icons/LogoBillWinnerBlack';
import { usePath } from '@packs/layouts/constants';
import { useDetails } from '@packs/models/policy/useDetails';
import { useStore } from '@packs/stores';

import { observer } from 'mobx-react';
import { NavLink, useLocation } from 'react-router-dom';

const { white, dark } = colors;

export const MobileHeader = observer(({ toggleDrawer, openDrawer, bgFilled, handleLogoRedirect }) => {
  const { ROOT_PATH } = usePath();
  const { leaderboardStore } = useStore();
  const location = useLocation();
  const { currentPolicy } = useDetails();

  const { pathname } = location;
  const isLeaderboard = pathname.match(/\/leaderboard/g)?.length > 0;

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const onClickLogo = () => {
    if (openDrawer) {
      toggleDrawer();
    }
    handleLogoRedirect();
  };

  const onShowBillBuddy = () => {
    leaderboardStore.showBillBuddy(true);
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          minHeight: '56px',
          backgroundColor: bgFilled ? white.main : 'transparent',
          transition: 'background-color .2s',
          zIndex: '100'
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ p: 1 }}>
          <Stack direction='row' alignItems='center' spacing={3}>
            <NavLink to={ROOT_PATH} onClick={onClickLogo}>
              {bgFilled ? <LogoBlackIcon /> : <LogoIcon />}
            </NavLink>
          </Stack>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Box sx={{ position: 'relative' }}>
              <IconButton onClick={() => setIsSearchOpen(prev => !prev)}>
                <SearchIcon sx={{ color: bgFilled ? dark.main : white.main }} />
              </IconButton>
              {isSearchOpen && (
                <Box
                  sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: colors.white.main,
                    zIndex: 99
                  }}
                >
                  <SearchBusinessCategory
                    overlaySearch
                    bgFilled={true}
                    fullWidth
                    fullScreen={true}
                    onClose={() => setIsSearchOpen(false)}
                  />
                </Box>
              )}
            </Box>
            {isLeaderboard && currentPolicy?.id && (
              <div className='bill-buddy-icon' onClick={onShowBillBuddy}>
                <CalendarTodayRoundedIcon />
              </div>
            )}
            <IconButton onClick={toggleDrawer}>
              <MenuIcon sx={{ color: bgFilled ? dark.main : white.main }} />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      <MobileMenu {...{ openDrawer, toggleDrawer }} />
    </>
  );
});
