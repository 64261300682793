import React, { useCallback, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import GroupRounded from '@mui/icons-material/GroupRounded';
import SearchIcon from '@mui/icons-material/Search';
import { TypographyProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import api from '@packs/apis/company';
import colors from '@packs/assets/theme/base/colors';
import { CategoryIconBox } from '@packs/components/MaterialUi/CategoryIconBox/CategoryIconBox';
import { Avatar } from '@packs/components/UI';
import { useUserSelectors } from '@packs/hooks/useUserSelectors';
import { ICategory, ICompany } from '@packs/interfaces/rootInterfaces';
import { pathGen, usePath } from '@packs/layouts/constants';
import { handleVisitCompany } from '@packs/lib/helpers/additionalHelpers';
import { EXCLUDED_CATEGORIES } from '@packs/models/category/constants';
import { categoryHavePolicy } from '@packs/models/category/functions';
import { useStore } from '@packs/stores';
import { EmptyPlaceholder } from '@shared/components/EmptyPlaceholder/EmptyPlaceholder';
import { IconBox } from '@shared/components/IconBox/IconBox';
import { rootCategoriesLanding } from '@shared/constants/categories';
import { useWindowSize } from '@shared/hooks/useWindowSize';

import debounce from 'lodash-es/debounce';
import { useNavigate } from 'react-router-dom';

const { secondary, white, dark } = colors;

const filledStyle = {
  '& .MuiInputBase-root': {
    backgroundColor: 'rgba(245, 245, 245, 0.20)',
    color: '#fff',
    'input::-webkit-input-placeholder': {
      opacity: 1
    },
    'input::-moz-placeholder': {
      opacity: 1
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      color: 'initial'
    }
  },
  '&:not(.Mui-focused)': {}
};

const SearchOption = ({ option, onSelect }) => {
  return (
    <Box
      pt={2}
      pb={2}
      sx={{
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '1px',
          backgroundColor: 'rgba(0, 0, 0, 0.08)'
        },
        '&:last-child:after': {
          content: 'none'
        }
      }}
    >
      <Box sx={{ mb: 0.5, pl: 2 }}>
        <Typography variant={'body4' as TypographyProps['variant']}>{option.label}:</Typography>
      </Box>
      {option.options && (
        <>
          {option.options.map(item => (
            <Stack
              key={item.id}
              direction='row'
              spacing={1}
              alignItems='center'
              sx={{
                py: 1,
                px: 2,
                cursor: 'pointer',
                '&:hover': { backgroundColor: colors.secondary.hover }
              }}
              onClick={() => onSelect(item)}
            >
              {(item?.avatarUrl || item?.emptyIcon) && (
                <div>
                  <Avatar size={24} avatarUrl={item?.avatarUrl}>
                    {item?.emptyIcon}
                  </Avatar>
                </div>
              )}
              {item?.type === 'associations' && (
                <IconBox
                  containerSize='24px'
                  icon={<GroupRounded sx={{ fontSize: '16px' }} />}
                  borderRadius='4px'
                  color='#673AB7'
                  backgroundColor='rgba(156, 39, 176, 0.08)'
                />
              )}
              {item?.type === 'customCampaigns' && (
                <IconBox
                  containerSize='24px'
                  icon={<GroupRounded sx={{ fontSize: '16px' }} />}
                  borderRadius='4px'
                  color='#FF9800'
                  backgroundColor='rgba(255, 152, 0, 0.08)'
                />
              )}
              {(item?.type === 'residentials' || item?.type === 'businessResidentials') && (
                <IconBox
                  containerSize='24px'
                  icon={<FmdGoodRoundedIcon sx={{ fontSize: '16px' }} />}
                  borderRadius='4px'
                  color={colors.secondary.main}
                  backgroundColor='rgba(1, 103, 205, 0.08)'
                />
              )}
              {item?.type === 'category' && item?.enName && item?.root && (
                <CategoryIconBox
                  category={item}
                  containerSize={24}
                  iconSize={'16px'}
                  backgroundColor={secondary.transparent}
                  color={secondary.main}
                />
              )}
              <Box>
                <Typography variant='body2'>{item?.label}</Typography>
                {(item?.type === 'associations' ||
                  item?.type === 'customCampaigns' ||
                  item?.type === 'residentials') && (
                  <Typography variant='body5' sx={{ color: colors.text.secondary }}>
                    {`(${item?.countryRegion}, ${item?.city}.${
                      item?.eirCodeAlias?.eircode ? ` ${item.eirCodeAlias.eircode}` : ''
                    })`}
                  </Typography>
                )}
              </Box>
            </Stack>
          ))}
        </>
      )}
    </Box>
  );
};

export interface ICompaniesSearchResponse {
  errors?: string[];
  companies: ICompany[];
  categories: ICategory[];
}

export const SearchBusinessCategory = ({
  placeholder = 'Search by product, business or location...',
  bgFilled = false,
  fullWidth = false,
  fullScreen = false,
  onClose
}) => {
  const { isMobile } = useWindowSize();
  const { countryCode, categoryStore, companiesStore, userStore } = useStore();
  const {
    DASHBOARD_LANDING_PATH_FN,
    DASHBOARD_ROOT_LANDING_PATH_FN,
    DASHBOARD_MY_RESULTS_PATH_FN,
    MY_OFFERS_DETAILED_PATH_FN
  } = usePath();
  const navigate = useNavigate();
  const { currentUser } = useUserSelectors();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<{ label: string; options: Record<string, any> }[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const promiseOptions = (keyWord = '') => {
    setLoading(true);
    api
      .fetchCompaniesSearch({
        countryCode,
        keyWord,
        excludedCategories: EXCLUDED_CATEGORIES
      })
      .then((res: ICompaniesSearchResponse) => {
        let list: typeof options = [];

        if (!res?.errors || res?.errors.length === 0) {
          const companies = res?.companies.map(item => ({
            id: item.id,
            value: item.urlId,
            label: item.name,
            type: 'business',
            avatarUrl: item.avatarUrl
          }));

          const categories = res?.categories.map(item => ({
            ...item,
            value: item.code,
            label: item.name,
            type: 'category'
          }));

          const associations = res?.associations.map(item => ({
            ...item,
            value: item.code,
            label: item.name,
            countryRegion: item?.countryRegion?.name,
            city: item?.locality?.name,
            route: item.route,
            type: 'associations'
          }));

          const customCampaigns = res?.customCampaigns.map(item => ({
            ...item,
            value: item.code,
            label: item.name,
            countryRegion: item?.countryRegion?.name,
            city: item?.locality?.name,
            route: item.route,
            type: 'customCampaigns'
          }));

          const residentials = res?.residentials.map(item => ({
            ...item,
            value: item.code,
            label: item?.residentialAreaName || item?.name || 'Residential',
            countryRegion: item?.countryRegion?.name,
            city: item?.locality?.name,
            route: item.route,
            type: 'residentials'
          }));
          const businessResidentials = res?.businessResidentials.map(item => ({
            ...item,
            value: item.code,
            label: item?.residentialAreaName || item?.name || 'Local business',
            countryRegion: item?.countryRegion?.name,
            city: item?.locality?.name,
            route: item.route,
            type: 'businessResidentials'
          }));
          const offers = res?.offers.map(item => ({
            id: item.id,
            value: item.urlId,
            label: item.offerMessage,
            type: 'offers',
            avatarUrl: item.bannerUrl,
            emptyIcon: true
          }));

          list = [
            ...[{ label: 'Product Groups', options: categories }],
            ...[{ label: 'Business Pages', options: companies }],
            ...[{ label: 'Associations', options: associations }],
            ...[{ label: 'Custom groups', options: customCampaigns }],
            ...[{ label: 'Residential Bill Groups', options: residentials }],
            ...[{ label: 'Business Groups', options: businessResidentials }],
            ...[{ label: 'Offers', options: offers }]
          ].filter(item => item.options.length > 0);
        }

        setOptions(list);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = useCallback(
    debounce(keyWord => {
      promiseOptions(keyWord);
    }, 1000),
    []
  );

  const categoryUrl = data => {
    let url = data.route;
    const category = data?.category;

    if (
      userStore?.currentUserGroups.some(item => item.id === data?.campaignGroup?.id) &&
      categoryHavePolicy(category, categoryStore.categoriesWithPolicy, currentUser)
    ) {
      categoryStore.setCurrentCategory(category);
      url = DASHBOARD_MY_RESULTS_PATH_FN(category).concat(`?campaignGroupId=${data?.campaignGroup?.id}`);
    }

    return url;
  };

  const onSelect = data => {
    setOpen(false);
    setInputValue('');
    if (onClose) {
      onClose(data);
    }
    if (data.type === 'business') {
      handleVisitCompany(data);
      navigate(pathGen('BUSINESS', { countryCode, company: { urlId: data.value } }));
    }

    if (data.type === 'category') {
      categoryStore.setCurrentCategory(data);
      companiesStore.clearFilters();

      const isPopularRootCategory = rootCategoriesLanding.some(popular => popular === data.code);

      if (categoryHavePolicy(data, categoryStore.categoriesWithPolicy, currentUser)) {
        navigate(DASHBOARD_MY_RESULTS_PATH_FN(data));
      } else if (isPopularRootCategory) {
        navigate(DASHBOARD_ROOT_LANDING_PATH_FN(data));
      } else {
        navigate(DASHBOARD_LANDING_PATH_FN(data));
      }
    }

    if (data.type === 'offers') {
      navigate(MY_OFFERS_DETAILED_PATH_FN(data.id));
    }

    if (data?.route) {
      const url = categoryUrl(data);
      navigate(url);
    }
  };

  useEffect(() => {
    if (open || fullScreen) {
      promiseOptions('');
    }
  }, [open, fullScreen]);

  useEffect(() => {
    if (open || fullScreen) {
      fetchData(inputValue);
    }
  }, [inputValue]);

  const filterOptions = options => {
    return options;
  };

  if (fullScreen) {
    return (
      <Box>
        <Box sx={{ px: 2, py: 1, background: colors.secondary.gradient }}>
          <Stack direction='row' alignItems='center' spacing={0.5} sx={{ ...filledStyle }}>
            <TextField
              placeholder={placeholder}
              variant='outlined'
              label=''
              fullWidth
              size='medium'
              onChange={e => setInputValue(e.target.value)}
              inputProps={{ sx: { py: 1, pl: 0.5, fontSize: 16, lineHeight: '24px' } }}
              InputProps={{
                type: isMobile ? 'text' : 'search',
                sx: { py: 0.5 },
                startAdornment: <SearchIcon />,
                endAdornment: <>{loading ? <CircularProgress color='inherit' size={20} /> : null}</>
              }}
            />
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ color: colors.white.main }} />
            </IconButton>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 80px)', overflowY: 'scroll' }}>
          {!loading && options.length === 0 ? (
            <Box sx={{ pt: 8 }}>
              <EmptyPlaceholder title='No results found.' />
            </Box>
          ) : (
            options.map(option => <SearchOption key={option.label} option={option} onSelect={onSelect} />)
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ ...(!bgFilled ? filledStyle : {}), width: '100%' }}>
      <Autocomplete
        id='header-search'
        sx={{
          width: '100%',
          maxWidth: { xs: 350, xl: 456 }
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        size='small'
        value={null}
        filterOptions={filterOptions}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        fullWidth={fullWidth}
        options={options}
        loading={loading}
        renderOption={(props, option) => <SearchOption option={option} onSelect={onSelect} />}
        PaperComponent={({ children }) => (
          <Box
            mt={1}
            sx={{
              backgroundColor: white.main,
              borderRadius: '4px',
              border: `1px solid ${dark.transparent}}`,
              '& .MuiAutocomplete-listbox': {
                p: 0
              }
            }}
          >
            {children}
          </Box>
        )}
        noOptionsText='No results'
        renderInput={params => (
          <Box>
            <TextField
              {...params}
              placeholder={placeholder}
              variant='outlined'
              label=''
              size={isMobile ? 'medium' : 'small'}
              InputProps={{
                ...params.InputProps,
                type: isMobile ? 'text' : 'search',
                startAdornment: <SearchIcon />,
                endAdornment: <>{loading ? <CircularProgress color='inherit' size={20} /> : null}</>
              }}
            />
          </Box>
        )}
      />
    </Box>
  );
};
