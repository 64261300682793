import { action, observable, runInAction } from 'mobx';

import companyApi from '@packs/apis/company';
import userApi from '@packs/apis/currentUser';
import { renewalDetailApi } from '@packs/apis/renewalDetail';

export class HomeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable renewals = [];
  @observable renewalsPriceData = {};
  @observable isLoadingRenewals = false;
  @observable userDetails = [];

  @observable winnerCompanies = [];

  @observable activity = {
    myReminders: { totalCount: 0, data: [] },
    myDetails: { totalCount: 0, data: [] },
    myReviews: { totalCount: 0, data: [] },
    myTips: { totalCount: 0, data: [] },
    myQAndA: { totalCount: 0, data: [] }
  };

  @action fetchUserCategories(attrs, onSuccess, onError) {
    this.setRenewals([]);
    this.setLoadingRenewals(true);

    userApi.fetchUserCategories(attrs).then(res => {
      if (res?.errors?.length) {
        onError && onError(res.errors);
      } else if (res) {
        // this.setInitialRenewalsPriceData(res);
        this.setRenewals(res);
        this.setLoadingRenewals(false);
        onSuccess && onSuccess(res);
      }
    });
  }

  @action fetchUserCategoriesPricePerPeriod(attrs) {
    userApi.fetchUserCategoriesPricePerPeriod(attrs).then(res => {
      if (res) {
        const newRenewal = [...this.renewals].map(cat => {
          const resCat = res.find(item => item.id === cat.id);
          return { ...cat, ...resCat };
        });

        this.setRenewals(newRenewal);
      }
    });
  }

  @action fetchUserCategoryByPolicy(attrs, itemId) {
    userApi
      .fetchUserCategoryByPolicy(attrs)
      .then(res => {
        if (res) {
          this.setRenewalPriceData(itemId, res);
        }
      })
      .catch(() => {
        this.setRenewalPriceData(itemId, { averagePriceData: null, minPriceData: null });
      });
  }

  @action dashboardPinCategory(categoryId) {
    userApi.dashboardPinCategory({ categoryId }).then(res => {
      if (res?.errors.length === 0) {
        const list = this.renewals.map(item => {
          if (item.id === categoryId) {
            return { ...item, isPinned: res.isPinned };
          }

          return item;
        });
        this.setRenewals(list);
      }
    });
  }

  @action dashboardUnPinCategory(categoryId) {
    userApi.dashboardUnPinCategory({ categoryId }).then(res => {
      if (res?.errors.length === 0) {
        const list = this.renewals.map(item => {
          if (item.id === categoryId) {
            return { ...item, isPinned: res.isPinned };
          }

          return item;
        });
        this.setRenewals(list);
      }
    });
  }

  @action fetchUserRenewalDetails(attrs, onSuccess, onError, refetch) {
    if (!refetch) {
      this.setUserDetails([]);
    }

    renewalDetailApi.fetchUserRenewalDetails(attrs).then(res => {
      if (res?.errors?.length) {
        onError && onError(res.errors);
      } else if (res) {
        this.setUserDetails(res);
        onSuccess && onSuccess(res);
      }
    });
  }

  @action updateRenewalDetail({ id, data }) {
    this.userDetails = this.userDetails.map(item => {
      if (item.id === id) {
        return {
          ...item,
          ...data
        };
      }

      return item;
    });
  }

  @action updateIsAutoQuote = ({ policyId, smartReminderId, isAutoQuote }) => {
    renewalDetailApi.updateIsAutoQuote({ id: smartReminderId, isAutoQuote }).then(res => {
      if (!res?.errors?.length) {
        runInAction(() => {
          this.userDetails = this.userDetails.map(detail => {
            if (detail.id === policyId) {
              return {
                ...detail,
                smartReminder: { ...detail.smartReminder, isAutoQuote }
              };
            }

            return detail;
          });
        });
      }
    });
  };

  @action updateRenewalsSmartReminder({ policyId, resp }) {
    const details = this.userDetails.map(detail => {
      if (detail.id === policyId) {
        detail.smartReminder = resp?.smartReminder;
      }

      return detail;
    });
    this.setUserDetails(details);
  }

  @action updateRenewalsDetail({ id, renewalDetail }) {
    const renewals = this.renewals.map(item => {
      if (item.id === id) {
        const updatedRenewal = { ...item };
        if (updatedRenewal.renewalDetails.length) {
          updatedRenewal.renewalDetails = updatedRenewal.renewalDetails.map(detail => {
            if (detail.id === renewalDetail.id) {
              return { ...renewalDetail };
            }
            return detail;
          });
        } else {
          updatedRenewal.renewalDetails = [renewalDetail];
        }

        return updatedRenewal;
      }

      return item;
    });

    this.setRenewals(renewals);
  }

  @action fetchUserProfileActivity(attrs = {}) {
    userApi.userProfileActivity(attrs).then(res => {
      this.setActivity(res);
    });
  }

  @action fetchWinnerCompanies(attrs = {}) {
    attrs.currentUserAssign = 'true';

    companyApi.fetchCompaniesList(attrs).then(res => {
      this.setWinnerCompanies(res.companies);
    });
  }

  @action removeBill({ id }) {
    this.renewals = this.renewals.filter(item => item.id !== id);
  }

  @action setRenewals(arr) {
    this.renewals = arr;
  }

  @action setInitialRenewalsPriceData(res) {
    this.renewalsPriceData = res.reduce((acc, item) => {
      acc[item.id] = undefined;
      return acc;
    }, {});
  }

  @action setRenewalPriceData(id, data) {
    this.renewalsPriceData[id] = data;
  }

  @action setLoadingRenewals(loading) {
    this.isLoadingRenewals = loading;
  }

  @action setUserDetails(arr) {
    this.userDetails = arr;
  }

  @action setActivity(data) {
    this.activity.myReminders = data.myReminders;
    this.activity.myDetails = data.myDetails;
    this.activity.myReviews = data.myReviews;
    this.activity.myTips = data.myTips;
    this.activity.myQAndA = data.myQAndA;
  }

  @action setWinnerCompanies(data) {
    this.winnerCompanies = data;
  }
}
