import React, { FC, useEffect, useState } from 'react';

import { AutocompleteProps, TypographyProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import api from '@packs/apis/cities';
import { defaultLocality } from '@packs/models/company/constants';

import { observer } from 'mobx-react';

interface IProps extends Partial<AutocompleteProps<any, any, any, any>> {
  onChange: (val: any) => void;
  disabled?: boolean;
  withTitle: boolean;
  location?: string;
  locality?: object;
}

export const DashboardLocalityFilter: FC<IProps> = observer(
  ({ onChange, disabled = false, size = 'medium', withTitle, location, locality = defaultLocality }) => {
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const emptyLocation = !location || location === 'all';

    const loadOptions = async (keyWord = '') => {
      if (emptyLocation || searchValue.length === 0) {
        setOptions([]);
        return null;
      }
      return api.fetchCitiesList({ admName: location, keyWord, page: 1, perPage: 20 }).then(res => {
        if (!res?.errors || res?.errors.length === 0) {
          setOptions(res.data.map(c => ({ value: c.name, label: c.name })));
        } else {
          setOptions([]);
        }
      });
    };

    const onInputChange = e => {
      setSearchValue(e.target.value);
    };

    useEffect(() => {
      loadOptions(searchValue);
    }, [location, searchValue]);

    useEffect(() => {
      setSearchValue('');
    }, [location]);

    return (
      <>
        {withTitle && (
          <Box sx={{ mb: size === 'small' ? 0.5 : 1 }}>
            <Typography variant={(size === 'small' ? 'body6' : 'body2') as TypographyProps['variant']}>
              Locality
            </Typography>
          </Box>
        )}
        <Autocomplete
          disabled={disabled}
          value={locality}
          options={[defaultLocality, ...options]}
          disableClearable={true}
          onChange={(e, val) => onChange && onChange(val)}
          renderInput={params => (
            <TextField
              {...params}
              label={withTitle ? '' : 'Locality'}
              placeholder='Type city area, town or village'
              size={size}
              onChange={onInputChange}
              InputProps={{
                ...params.InputProps
              }}
            />
          )}
        />
      </>
    );
  }
);
