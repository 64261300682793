export const trust_network = 'trust_network';
export const association = 'association';
export const discount_code = 'discount_code';
export const location = 'location';
export const general = 'general';
export const group = 'group';
export const offerTypes = [
  {
    value: general,
    label: 'General Offer (Recommended)'
  },
  {
    value: group,
    label: 'Group Offer'
  }
];
export const offerTypeLabel = {
  [general]: 'General',
  [discount_code]: 'Discount code',
  [trust_network]: 'Trust network',
  [association]: 'Association',
  [group]: 'Group'
};

export const activeStatus = 'active';
export const archivedStatus = 'archived';
export const draftStatus = 'draft';

export const offerStatusList = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: draftStatus,
    label: 'Draft'
  },
  {
    value: activeStatus,
    label: 'Active'
  },
  {
    value: archivedStatus,
    label: 'Archive'
  }
];

export const local_services = 'local_services';
